import { useEffect, RefObject } from 'react';

const useOutsideClick = (
  callback: () => void,
  ref: RefObject<HTMLElement>,
  disabled?: boolean
) => {
  useEffect(() => {
    if (!disabled) return;
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        ref.current &&
        event.target instanceof Node &&
        !ref.current.contains(event.target)
      ) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, callback, disabled]);
};

export default useOutsideClick;
