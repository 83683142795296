import { css } from '@emotion/react';
import { Flex } from 'components/box';
import Button from 'components/button';
import { Smartphone, X as XIcon } from 'react-feather';
import { useCallback, useEffect, useRef, useState } from 'react';
import resourceUrl from '../../../util/make-url';
import { NAVIGATION_BAR_HEIGHT_TABLET } from 'components/_global/navigation-bar';
import { useNavigationBarActive } from 'hooks/app';
import useOutsideClick from 'hooks/use-outside-click';
import { zIndices } from 'constants/z-indices';
import theme, { gradientConfettiPalette } from 'themes/onedayonly';
import { EventNamesEnum } from 'types/third-party/gtm';
import { trackAppDownloadWidgetInteraction } from '../../../util/tag-manager';
import { isBrowserWindowAvailable } from '../../../util/is-browser-window-available';

const SIZE = 45;
const THRESHOLD_PERCENT = 30;
const THRESHOLD_PIXELS = 2500;

const useScrollThreshold = ({
  pixels,
  percent,
  callback,
}: {
  pixels: number;
  percent?: number;
  callback: (pastThreshold: boolean) => void;
}) => {
  useEffect(() => {
    if (isBrowserWindowAvailable()) {
      const handleScroll = () => {
        const h = document.documentElement;
        const b = document.body;

        const scrollTop = h.scrollTop || b.scrollTop;
        const scrollHeight = h.scrollHeight || b.scrollHeight;

        const pastThreshold =
          scrollTop > pixels ||
          (typeof percent !== 'undefined' &&
            (scrollTop / (scrollHeight - h.clientHeight)) * 100 > percent);

        callback(pastThreshold);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [pixels, percent, callback]);
};

const AppDownloadWidget = () => {
  const [isHidden, setIsHidden] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const isVisibleRef = useRef(false);
  const isNavigationBarActive = useNavigationBarActive();

  useOutsideClick(() => setIsHidden(true), containerRef, true);

  const pastThresholdCallback = useCallback((pastThreshold: boolean) => {
    if (isVisibleRef.current !== pastThreshold) {
      setIsVisible(pastThreshold);
      setIsHidden(true);
      isVisibleRef.current = pastThreshold;
    }
  }, []);

  useScrollThreshold({
    pixels: THRESHOLD_PIXELS,
    percent: THRESHOLD_PERCENT,
    callback: pastThresholdCallback,
  });

  return (
    <Flex
      className={isVisible ? 'visible' : ''}
      ref={containerRef}
      alignItems="flex-end"
      width={`${SIZE}px`}
      height={`${SIZE}px`}
      mb={4}
      padding={0}
      zIndex={zIndices.QUICK_APP_DOWNLOAD_CTA}
      css={theme => css`
        margin-top: -${theme.space[4] + SIZE}px;
        position: sticky;
        opacity: 0;
        transition: opacity 0.7s;
        width: 300px;
        left: calc(100% - ${theme.space[4] * 2 + Math.floor(SIZE / 4)}px);
        bottom: ${
          isNavigationBarActive
            ? `${NAVIGATION_BAR_HEIGHT_TABLET + 16}px`
            : `${theme.space[4]}px`
        };

        &.visible {
          pointer-events: all;
          opacity: 1;
      `}
    >
      <Flex
        flexDirection="column"
        padding={theme.space[2]}
        css={theme => css`
          background: linear-gradient(
            #27bbe8,
            ${gradientConfettiPalette.blue.middle},
            ${gradientConfettiPalette.blue.end},
            ${gradientConfettiPalette.blue.confetti}
          );
          border-radius: ${theme.radii[3]}px;
          transform: ${isHidden ? 'translateX(1000px)' : ' translateX(-50px)'};
          transition: transform 0.3s ease-in-out;
        `}
      >
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button
            variant="flat"
            onClick={() => setIsHidden(true)}
            p={0}
            mb={-3}
            width="20px"
            zIndex={zIndices.SCROLL_TO_TOP_BUTTON}
          >
            <XIcon
              css={theme => css`
                color: ${theme.colors.darkestGrey};
              `}
            />
          </Button>
        </Flex>
        <img
          src={resourceUrl('odo/app-download-widget/copy.png', {
            width: 300,
            isAssetUrl: true,
          })}
          alt="Download the OneDayOnly App using the QR Code below"
          css={css`
            width: 100%;
          `}
        />
        <img
          src={resourceUrl('odo/app-download-widget/qr-code.png', {
            width: 400,
            isAssetUrl: true,
          })}
          alt="QR Code that links to relevant app store to download the OneDayOnly app"
          css={css`
            width: 100%;
          `}
        />
        <Flex flexDirection="row" justifyContent="space-between" mt={2}>
          <img
            src={resourceUrl(
              'odo/app-download-widget/google-play-store-icon.png',
              {
                width: 100,
                isAssetUrl: true,
              }
            )}
            alt="Google Play Logo"
            css={css`
              width: 32%;
            `}
          />
          <img
            src={resourceUrl('odo/app-download-widget/app-store-icon.png', {
              width: 100,
              isAssetUrl: true,
            })}
            alt="App Store Logo"
            css={css`
              width: 32%;
            `}
          />
          <img
            src={resourceUrl(
              'odo/app-download-widget/hauwei-app-gallery-icon.png',
              {
                width: 100,
                isAssetUrl: true,
              }
            )}
            alt="Hauwei AppGallery Logo"
            css={css`
              width: 32%;
            `}
          />
        </Flex>
      </Flex>
      <Button
        className={isVisible ? 'visible' : ''}
        onClick={() => {
          setIsHidden(!isHidden);
          trackAppDownloadWidgetInteraction(
            EventNamesEnum.openAppDownloadWidget
          );
        }}
        borderRadius="50%"
        px="10px"
        fontColor="primary"
        variant="flat"
        color="primary"
        zIndex={zIndices.SCROLL_TO_TOP_BUTTON}
        mb={5}
        css={theme => css`
          position: sticky;
          right: 30px;
          bottom: ${isNavigationBarActive
            ? `${NAVIGATION_BAR_HEIGHT_TABLET + 16}px`
            : `${theme.space[5]}px`};
          border: none;
          width: ${SIZE}px;
          height: ${SIZE}px;
          background: ${theme.colors.white};
          box-shadow: ${theme.shadows.medium};
          opacity: 0;
          transition: opacity 0.7s;

          &.visible {
            pointer-events: all;
            opacity: 1;
          }
        `}
      >
        {isHidden ? (
          <Smartphone
            className={!isHidden ? 'visible' : undefined}
            size={26}
            strokeWidth={1.5}
          />
        ) : (
          <XIcon size={26} strokeWidth={1.5} />
        )}
      </Button>
    </Flex>
  );
};

export default AppDownloadWidget;
